<template>
  <div>
    <v-runtime-template :template="`<span>${config.indexTemplate}</span>`" />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import VRuntimeTemplate from 'v-runtime-template';

export default {
  name: 'IndexPage',
  components: {
    VRuntimeTemplate,
  },

  data() {
    return {
      availableBlocks: ['list', 'kanban'], /* , 'calendar' */
      mouseOveredPanel: undefined,
    };
  },
  computed: {
    ...mapState({
      config: (state) => state.config.config,
    }),
    panels: {
      get() {
        return this.$store.state.abstractElements.objects.panels;
      },
    },
    panelSchema: {
      get() {
        const schemas = this.$store.state.abstractElements.objects.schemas.objects;
        if (schemas) {
          for (let i = 0; i < schemas.length; i++) {
            if (schemas[i].name === 'panels') {
              return schemas[i];
            }
          }
        }
        return undefined;
      },
    },
  },
};
</script>
<style scoped>
  .element-list-panels {
    display: flex;
    flex-wrap: wrap;
  }
  .elements-panel {
    margin: 20px;
    width: 25%;
  }
</style>
